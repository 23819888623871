<template>
  <div>
    <div>
      <CallSidebar v-if="lesson && !terminal_error"
                   :muted="muted"
                   :lesson="lesson"
                   :videomuted="videomuted"
                   @mute="mute"
                   @videomute="videomute"
                   :students="students"
                   :usersdata="usersdata"
                   :linkchat="linkchat"
                   @stop="stop"
                   @fullVideo="dataTransfer"/>
      <transition name="fade">
        <fullsizeCover v-if="fullVideoOpen"/>
      </transition>
    </div>
    <section class="lesson">
      <div v-if="!terminal_error">
        <!--        <a style="position: absolute; right: 40px; top: 40px; z-index: 10" -->
        <!--           v-if="socket" -->
        <!--           @click="testMethod">-->
        <!--          {{ socket.connected ? 'sc' : 'snc' }} {{ socketTestMsg }}-->
        <!--        </a>-->
        <div v-if="loading" class="vh-100 d-flex justify-content-center align-items-center">
          <Loader/>
        </div>
        <template v-else>
          <div class="container-left" :class="{ sidebarOpened: bottomBurger}">
            <Canvas v-if="canvasLaunched"
                    ref="canvascomponent"
                    :config="peerConfig"
                    :usersdata="usersdata"
                    :userId="userId"
                    :homeworkStudentId="homeworkStudentId"
                    :lesson="lesson"
                    view="lesson"
                    @stop="stop"
                    :rates="rateenabled"
                    @homeworkdone="homeworkDone"/>
            <div class="video-row"
                 :class="{
                    half: lessonModesEnabled.length === 1,
                    fourth: lessonModesEnabled.length > 1 || (lessonModesEnabled.length === 1 && lessonModeFull)
                  }"
                 v-if="!homework && (numOfUsers > 0 || !videomuted)">
              <LinkChat v-if="linkchat && lesson && !jaasEnabled"
                        :class="{
                          half: lessonModesEnabled.length === 1,
                          fourth: lessonModesEnabled.length > 1 || (lessonModesEnabled.length === 1 && lessonModeFull)
                        }"
                        :lesson="lesson"
                        :mirotalk="mirotalk"/>
              <JassIframe v-if="linkchat && lesson && jaasEnabled"
                          :class="{
                          half: lessonModesEnabled.length === 1,
                          fourth: lessonModesEnabled.length > 1 || (lessonModesEnabled.length === 1 && lessonModeFull)
                        }"
                          :lesson="lesson"/>
              <div v-if="!linkchat && lesson" class="vh-100 overflow-scroll">
                <div class="video-grid" :class="{ one: numOfUsers == 1, two: numOfUsers == 2 }">
                  <CallParticipantTeaser id="localVideo"
                                         v-if="localStream"
                                         :local="true"
                                         @toggleScreenShare="toggleScreenShare"
                                         :screenSharing="screenSharing"
                                         :style="[fullVideoOpen ? {'visibility': 'hidden'} : {'visibility': 'visible' }]"
                                         :user="$store.state.user"
                                         :numOfUsers="numOfUsers"
                                         :uid="$store.state.user.id"
                                         :udata="{ video: !videomuted, remotestream: displayStream ? displayStream : localStream, audio: !muted, host: host }"/>
                  <!--                  <template v-for="n in 5">-->
                  <template v-for="(udata, peerUserId) in usersdata">
                    <template v-for="(stream, streamInd) in udata.remotestream">
                      <CallParticipantTeaser
                          :key="'remote-'+peerUserId+'-'+streamInd"
                          @click.native="selectUser(udata.dbUserId, peerUserId)"
                          :numOfUsers="numOfUsers"
                          :uid="udata.dbUserId"
                          :user="students[udata.dbUserId]"
                          :udata="{ ...udata, remotestream: stream }"/>
                    </template>
                  </template>
                  <!--                  </template>-->
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="container terminal-error pt-5" v-if="terminal_error">
        <div class="spacecard text-center py-5">
          <div class="emoji">😢</div>
          <div class="text">{{ terminal_error }}</div>
        </div>
      </div>
      <RateStudentModal :lesson="lesson"
                        :students="students"
                        v-if="rateenabled"
                        @graded="graded"/>
      <StudentsResponsesModalCustom v-if="isEnggoMethodics"
                                    :students="students"
                                    @showBlockResponses="showBlockResponses"/>
      <LessonPresentationPart :host="host"
                              :userId="userId"
                              view="lesson"
                              v-if="socket || homework"
                              :usersdata="usersdata"/>
      <VOffline v-if="myVideoPeer" @detected-condition="handleConnectivityChange"/>
    </section>
    <!--    <vue-resizable :left="72"-->
    <!--                   :width="300"-->
    <!--                   :disableAttributes="[]"-->
    <!--                   :minWidth="300"-->
    <!--                   :minHeight="200"-->
    <!--                   :height="450"-->
    <!--                   :active="['r','b', 'l', 't', 'lb', 'rb', 'lt', 'rt']"-->
    <!--                   dragSelector=".bg-primary">-->
    <!--      <div class="bg-primary h-100" style="z-index: 20">-->
    <!--        adsfasdf-->
    <!--      </div>-->
    <!--    </vue-resizable>-->
    <SelectMaterial  @selected="materialSelected"></SelectMaterial>
    <SourceSelectModal ref="sourceSelectModal"/>
  </div>
</template>

<script>

import io from 'socket.io-client'
import Peer from 'peerjs'
import fullsizeCover from "../../parts/specific/fullsizeCover"
import Canvas from "@/views/parts/class/Canvas"
import RateStudentModal from "@/views/parts/class/RateStudentModal"
import StudentsResponsesModalCustom from "@/views/parts/class/StudentsResponsesModalCustom"
import TaskStorage from '@/services/task-storage'
import {isChrome, isChromium, isElectron, isIOS} from 'mobile-device-detect'
import EventBus from '@/services/event-bus'
import CallSidebar from "@/views/parts/general/CallSidebar"
import Loader from "@/views/parts/general/Loader"
import { LessonsService, StudentPageService } from '@/services/api.service'
import {mapActions, mapGetters, mapState} from 'vuex'
import LinkChat from "../../parts/class/LinkChat"
import LessonPresentationPart from "../../parts/class/LessonPresentationPart"
import CallParticipantTeaser from "../../teasers/CallParticipantTeaser"
import {createMediaStreamFake} from "../../../helpers/fakeMediaStream"
import {ManagerService} from "../../../services/api.service"
import { VOffline } from 'v-offline';
import SelectMaterial from "../../parts/class/SelectMaterial.vue"
import JassIframe from "../../parts/class/JaasIframe.vue"
import SourceSelectModal from "../../parts/class/SourceSelectModal.vue"
// import VueResizable from 'vue-resizable'

export default {
  name: 'Room',
  components: {
    SourceSelectModal,
    JassIframe,
    SelectMaterial,
    LessonPresentationPart,
    LinkChat,
    RateStudentModal,
    CallParticipantTeaser,
    Canvas,
    fullsizeCover,
    CallSidebar,
    Loader,
    StudentsResponsesModalCustom,
    VOffline,
    // VueResizable
  },
  data() {
    return {
      connection: null,
      myVideoPeer: null,
      myVideoPeerTimer: null,
      socket: null,
      localStream: null,
      displayStream: null,
      usersdata: {},
      userId: null,
      muted: false,
      videomuted: false,
      rateenabled: false,
      canvasLaunched: true,
      loading: true,
      lesson: null,
      terminal_error: null,
      mode: "slide",
      homework: this.$route.params.homework && this.$route.params.homework === 'homework',
      homeworkStudentId: this.$route.params.homework && this.$route.params.studentid ? this.$route.params.studentid : null,
      setUsedTime: 600,
      tooltipshowed: false,
      mobileChatShow: false,
      showChatAndWords: false,
      showAudio: false,
      screenSharing: false,
      socketTimer: null,
      socketTestMsg: '',
      words: [],
      cards: [],
      students: {},
      selectedUserPeerId: null,
      mediaStreamConstraints: {
        audio: true,
        video: {
          // width: {max: 480, ideal: 160},
          // height: {max: 360, ideal: 120},
          width: {max: 480, ideal: 240},
          height: {max: 360, ideal: 180},
          aspectRatio: {ideal: 1.33333333}
          // width: { max: 4096, ideal: 4096 },
          // height: { max: 2160, ideal: 2160 },
        },
        // audio: {
        //     sampleSize: 16,
        //     channelCount: 2
        // }
      },
      peerConfig: {
        host: process.env.VUE_APP_PEER_HOST,
        port: process.env.VUE_APP_PEER_PORT,
        path: process.env.VUE_APP_PEER_PATH,
        secure: !!process.env.VUE_APP_PEER_SECURE,
        config: {
          iceServers: [{
            url: process.env.VUE_APP_STUN_URL,
            urls: process.env.VUE_APP_STUN_URL
          }, {
            url: process.env.VUE_APP_TURN_URL,
            urls: process.env.VUE_APP_TURN_URL,
            credential: process.env.VUE_APP_TURN_CREDENTIAL,
            username: process.env.VUE_APP_TURN_USERNAME
          }]
        }
      },
      room: Number(this.$route.params.id),
      code: this.$route.params.code,
      linkchat: false,
      mirotalk: false,
      audioPresentationEnabled: true,
      jaasEnabled: false
      // startTime: null,
    }
  },
  computed: {
    ...mapState({
      fullVideoOpen:      state => state.fullVideoOpen,
      host:               state => state.call.host,
      hostPeerId:         state => state.call.hostPeerId,
      selectedUser:       state => state.call.selectedUser,
      bottomBurger:       state => state.call.bottomBurger,
      linkchatUrl:        state => state.call.linkchatUrl,
      studentsBySubrooms: state => state.call.studentsBySubrooms,
      roomsSecondsLeft:   state => state.call.roomsSecondsLeft,
      blackboardJson:     state => state.call.blackboardJson,
      followed:           state => state.call.followed,
      lessonModesEnabled: state => state.call.lessonModesEnabled,
      lessonModeFull:     state => state.call.lessonModeFull,
      slidemode:          state => state.call.slidemode,
      selectedBlockId:    state => state.call.selectedBlockId,
      slide:              state => state.call.slide,
      finishedLesson:     state => state.call.finishedLesson,
      isMobile:           state => state.isMobile,
      viewMode:           state => state.call.viewMode,
      tasksStore:         state => state.call.tasksStore,
    }),
    ...mapGetters([
      'isEnggoMethodics'
    ]),
    numOfUsers() {
      return (this.usersdata ? Object.keys(this.usersdata).length : 0) + 1
    },
    idsOfUsers() {
      let arr = []
      for (var key in this.usersdata) {
        if ({}.hasOwnProperty.call(this.usersdata, key)) {
          arr.push(this.usersdata[key].dbUserId)
        }
      }
      return arr
    }
  },
  beforeDestroy() {
    if (this.socket) {
      console.log('Debug socket:', {event: 'manualdisconnect', room: this.room, userId: this.userId});
      this.socket.emit('manualdisconnect', this.room, this.userId)
    }
    if (this.localStream) {
      this.localStream.getVideoTracks()[0].stop()
      this.localStream.getAudioTracks()[0].stop()
    }
    this.myVideoPeer = null
    this.socket = null
    this.$store.commit('setSocket', null)
    this.localStream = null
    clearInterval(this.socketTimer)
  },
  methods: {
    ...mapActions([
      'getUserTasks',
    ]),
    dataTransfer() {
      console.log('Debug socket:', {
        action: 'videoFullData',
        videoMuted: this.videomuted,
        audioMuted: this.muted,
        host: this.host,
        usersdata: this.usersdata,
        remotestream: this.localStream,
        students: this.students,
      });

      EventBus.$emit('videoFullData', {
        videoMuted: this.videomuted,
        audioMuted: this.muted,
        host: this.host,
        usersdata: this.usersdata,
        remotestream: this.localStream,
        students: this.students,
      });
    },
    async stop() {
      if (this.host && !this.homework && this.lesson.lesson_type == 'lesson' && Object.values(this.students).length > 1) { // 1 is host
        this.rateenabled = true
        this.$nextTick(() => {
          this.$bvModal.show('ratestudentmodal')
        })
        console.log('Debug socket:', {event: 'customevent', room: this.room, userId: this.userId});
        this.socket.emit('customevent', 'finishlesson', this.room)
        return
      }
      if(this.host && this.lesson.lesson_type == 'demo') {
        await ManagerService.saveLesson(this.lesson.id, {
          material_id: this.lesson.material_id
        })
      }
      if(this.host && this.homeworkStudentId) {
        location.href = `/instructor/students/${this.homeworkStudentId}/history`
        return
      }
      if(this.host || this.homework) {
        location.href = '/'
        return
      }
      if(!this.host) {
        this.$swal({
          title: this.$t('done'),
          text: this.$t('homework_is_waiting_for_you'),
          confirmButtonText: this.$t('good'),
          customClass: {
            image:'swal-image-confirm',
          },
          imageUrl: '/images/tick2.png',
        }).then(() => {
          location.href = '/'
        })
      }
    },
    graded() {
      location.href = '/'
    },
    selectUser(dbUserId, peerUserId, nullify = false) {
      console.log('Debug socket:', {
        action: 'selectUser',
        host: this.host,
        selectedUser: this.selectedUser,
        dbUserId,
        peerUserId,
        nullify
      });

      if (this.host) {
        let f = true
        if (this.selectedUser == dbUserId || nullify) {
          this.sendDataThroughPeerConnections({type: 'follow', data: false, hostId: this.hostPeerId, dbUserId})
          this.selectedUserPeerId = null
          this.$store.commit('setSelectedUser', null)
          this.getUserTasks({ user_id: 'self', id: this.lesson.id, type: 'lesson' })
          f = false
        } else {
          this.selectedUserPeerId = peerUserId
          this.$store.commit('setSelectedUser', dbUserId)
          this.getUserTasks({user_id: dbUserId, id: this.lesson.id, type: 'lesson' })
        }
        // EventBus.$emit('taskClear')
        if(!nullify && peerUserId) {
          console.log('Debug socket:', {action: 'selectUser2', type: 'follow', data: f, hostId: this.hostPeerId, dbUserId});
          this.usersdata[peerUserId].connection.send({type: 'follow', data: f, hostId: this.hostPeerId, dbUserId})
        }
      }
    },
    sendDataThroughPeerConnections(data) {
      for (let prop in this.usersdata) {
        if (Object.prototype.hasOwnProperty.call(this.usersdata, prop)) {
          if (prop == this.userId) return
          let u = this.usersdata[prop]
          if (u.connection) {
            u.connection.send(data)
          }
        }
      }
    },
    async launchMedia(repeatedAttempt = false) {
      if(this.linkchat) {
        this.launchMediaAfterStream(null)
        return
      }
      try {
        let perms = await navigator.permissions.query({ name: "camera" })
        if(!['prompt', 'granted'].includes(perms?.state)) this.mediaStreamConstraints.video = false // user does not have camera or denied permissions
      } catch (e) {
        console.log(e)
      }
      navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints).then(stream => {
        this.launchMediaAfterStream(stream)
      }).catch(err => {
        if(!repeatedAttempt) {
          this.mediaStreamConstraints.video = false
          this.launchMedia(true)
        } else {
          this.loading = false
          this.$error(err)
          console.log('navigator.getUserMedia error: ', err)
        }
      })
    },
    async toggleScreenShare() {
      if(this.screenSharing) {
        this.turnOffScreenShare()
      } else {
        await this.screenShare()
      }
    },
    async screenShare() {
      try {
        const vids = document.querySelectorAll("video.othersvideotag")
        if(!document.pictureInPictureElement && document.pictureInPictureEnabled && vids.length) vids[0].requestPictureInPicture()
        let displayStream
        if(isElectron) {
          const sourceComponent = this.$refs.sourceSelectModal
          const result = await sourceComponent.chooseSource()
          displayStream = await navigator.mediaDevices.getUserMedia({
            video: {
              mandatory: {
                chromeMediaSource: 'desktop',
                chromeMediaSourceId: result?.id || null,
              }
            }
          })
        } else {
          displayStream = await navigator.mediaDevices.getDisplayMedia({
            video: { cursor: "always" }
          })
        }
        // const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true })
        // const audioTrack = audioStream.getAudioTracks()
        // displayStream.addTrack(audioTrack[0])
        this.displayStream = displayStream
        this.screenSharing = true
        displayStream.getVideoTracks()[0].onended = this.turnOffScreenShare // when user presses browser button
        Object.keys(this.usersdata).forEach(callUserId => {
          this.myVideoPeer.call(callUserId, displayStream, { metadata: { additional: true }})
        })
      } catch(e) {
        console.log(e)
      }
    },
    turnOffScreenShare() {
      if(this.displayStream) {
        const tracks = this.displayStream.getTracks()
        for(let i = 0; i < tracks.length; i++) tracks[i].stop()
      }
      navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints).then(stream => {
        this.screenSharing = false
        stream.getAudioTracks()[0].enabled = !this.muted
        this.localStream = stream
        this.displayStream = null
        Object.keys(this.usersdata).forEach(callUserId => {
          this.myVideoPeer.call(callUserId, stream)
        })
      }).catch(e => console.log(e))
      if (document.pictureInPictureElement) document.exitPictureInPicture()
    },
    launchMediaAfterStream(stream) {
      console.log("got own video stream")
      this.localStream = stream
      this.loading = false
      this.setUpPeerActions() // important to do it here when stream is already captured

      if(!this.socket) return

      this.socket.on('user-connected', (callUserId, dbUserId, isHost) => {
        console.log('user-connected with call user id ' + callUserId)
        console.log('user-connected with db user id ' + dbUserId)
        console.log('is host ' + isHost)
        if (!this.myVideoPeer) {
          console.log('my video peer is empty')
          return
        }

        this.$nextTick(() => {
          if(!Object.keys(this.students).includes(dbUserId.toString())) {
            this.updateStudentsList()
          }
        })

        if(this.host) LessonsService.addUserToClass(this.lesson.id, dbUserId)

        setTimeout(() => { // next tick not working
          console.log("calling joined person video")
          if(!this.linkchat && !this.localStream) return
          const peerCall = this.myVideoPeer.call(callUserId, this.localStream ? this.localStream : createMediaStreamFake())
          // console.log(peerCall)

          peerCall.on('stream', userVideoStream => {
            console.log('got new video stream from our call', callUserId)
            this.addVideoStream(userVideoStream, callUserId)
            if (this.host) {
              EventBus.$emit('sendallcanvas')
              this.sendStatusDataToSockets()
            } else {
              this.sendGuestStatusDataToSockets()
            }
          })
          peerCall.on('close', () => {
            console.log('close video stream')
            this.$delete(this.usersdata, callUserId)
          })
          peerCall.on('error', err => {
            console.log('video peer call error listener')
            console.log(err)
          })
          this.checkForInitUserConfig(callUserId)
          this.$set(this.usersdata[callUserId], "peer", peerCall)
        }, 100)
      })
    },
    addVideoStream(stream, userId = null, metadata = null) {
      this.checkForInitUserConfig(userId)
      if(metadata && metadata.additional && this.usersdata[userId]) {
        if(!this.usersdata[userId]['remotestream'].find(x => x.id === stream.id)) {
          stream.additional = true
          this.usersdata[userId]['remotestream'].push(stream)
        }
      } else {
        this.$set(this.usersdata[userId], 'remotestream', [stream])
      }
      let connection = this.myVideoPeer.connect(userId)
      this.settingPeerConnectionChannel(userId, connection)
      // if (this.host && !this.tooltipshowed) {
      //   this.$toast(this, this.$t('press_on_user_to_follow_him'))
      //   this.tooltipshowed = true
      // }
    },
    settingPeerConnectionChannel(userId, connection) {
      console.log('Debug socket:', {
        action: 'settingPeerConnectionChannel',
        room: this.room,
        userId: this.userId,
        userData: this.usersdata[userId],
        connection,
        host: this.host,
        dbId: this.lesson.lesson_type == 'demo' ? 'demo' : (this.$store.state.user ? this.$store.state.user.id : 'demo'),
        peerId: this.userId,
      });

      if(this.usersdata[userId]) this.$set(this.usersdata[userId], 'connection', connection)
      connection.on('open', () => {
        if (this.host) {
          setTimeout(() => {
            console.log('Debug socket:', {
              event: 'sendallcanvas',
              room: this.room,
              userId: this.userId,
              peerId: this.userId,
            });
            EventBus.$emit('sendallcanvas')
          }, 500)
        }
        connection.send({
          type: 'userId',
          dbId: this.lesson.lesson_type == 'demo' ? 'demo' : (this.$store.state.user ? this.$store.state.user.id : 'demo'),
          peerId: this.userId,
          host: this.host
        })
        connection.on('data', data => {
          console.log('Debug socket:', {
            action: 'settingPeerConnectionChannel.connection.on',
            data
          });
          this.processConnectionData(data)
        })
      })
    },

    setUpSockets(force = false) {
      if(this.socket && !force) {
        console.log('Debug socket:', {
          action: 'setUpSockets.connect',
          socket: this.socket,
        });
        this.socket.connect()
        return
      }

      this.socket = io(process.env.VUE_APP_SOCKET_SERVER, {
        // this.socket = io("https://study.enggoo.kz:9043", {
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000
      })

      console.log('Debug socket:', {
        action: 'setUpSockets.init',
        socket: this.socket,
      });

      this.$store.commit('setSocket', this.socket)

      this.socket.on('user-disconnected', userId => { // when someone closes tab
        console.log(`user-disconnected: ${userId}`)
        console.log('Debug socket:', {
          event: 'user-disconnecte',
          userId,
          userData: this.usersdata[userId],
          host: this.host,
        });
        if (this.usersdata[userId] && this.usersdata[userId].peer) {
          if(this.host) LessonsService.removeUserFromClass(this.lesson.id, this.usersdata[userId].dbUserId)
          this.usersdata[userId].peer.close()
          if (this.usersdata[userId] && this.usersdata[userId].dbUserId == this.selectedUser) {
            this.$store.commit('setSelectedUser', null)
          }
        }
        this.$delete(this.usersdata, userId)
      })

      this.socket.on('message', message => {
        console.log('Debug socket:', {
          event: 'message',
          message,
        });
        EventBus.$emit('newChatMessage', message)
      })

      this.socket.on('audio-switch', (userId, data) => {
        console.log('Debug socket:', {
          event: 'audio-switch',
          userId,
          data,
        });
        if ({}.hasOwnProperty.call(this.usersdata, userId)) {
          this.$set(this.usersdata[userId], 'audio', data)
        }
      })

      this.socket.on('video-switch', (userId, data) => {
        console.log('Debug socket:', {
          event: 'video-switch',
          userId,
          data,
        });
        if ({}.hasOwnProperty.call(this.usersdata, userId)) {
          this.$set(this.usersdata[userId], 'video', data)
        }
      })

      this.socket.on('hostidreceived', (hostPeerId) => {
        console.log('Debug socket:', {
          event: 'setHostPeerId',
          hostPeerId
        });
        this.$store.commit('setHostPeerId', hostPeerId)
      })

      this.socket.on('qr-uploaded', async (roomId, data) => {
        if(roomId != this.room) return
        if(data.userId == this.$store.state.user.id || data.userId == this.selectedUser) {
          await this.getUserTasks({ user_id: data.userId, id: this.lesson.id, type: 'lesson' })
          EventBus.$emit('slideTaskChangedSend', data.blockId, this.tasksStore[data.blockId], 'IM02')
        }
      })

      this.socket.on('finishlesson', () => {
        console.log('Debug socket:', {
          event: 'finishlesson',
          host: this.host,
        });
        if(!this.host) {
          this.stop()
        }
      })

      this.socket.on('slidechanged', (slide, finished) => {
        console.log('Debug socket:', {
          event: 'slidechanged',
          slide,
          finished
        });
        this.$store.commit('setSlide', slide)
        this.$store.commit('setFinishedLesson', finished)
      })

      this.socket.on('slidemodechanged', mode => { // betweeen homework and lesson
        console.log('Debug socket:', {
          event: 'setSlideMode',
          mode
        });
        this.$store.commit('setSlideMode', mode)
      })

      this.socket.on('state-data', (data) => {
        console.log('Debug socket:', {
          event: 'state-data',
          data
        });
        // console.log('got state data')
        this.mode = data.mode
        this.$store.commit('setStudentsBySubrooms', data.studentsBySubrooms)
        this.$store.commit('setRoomsSecondsLeft', data.secondsLeft)
        // this.$store.commit('setLinkchatUrl', data.linkchatUrl)
        this.$store.commit('setHostPeerId', data.hostPeerId)
        this.$nextTick(() => {
          EventBus.$emit('wbCanvasToJson', data.blackboardJson)
        })
      })

      this.socket.on('state-guest-data', (data) => {
        // console.log('got state guest data')
        // console.log(data)
        console.log('Debug socket:', {
          event: 'state-guest-data',
          data
        });
        this.$store.commit('setStudentsBySubrooms', data.studentsBySubrooms)
        this.$store.commit('setRoomsSecondsLeft', data.secondsLeft)
      })

      this.socket.on('youtubechanged', (data) => {
        console.log('Debug socket:', {
          event: 'youtubechanged',
          data
        });
        EventBus.$emit('youtubeChangeReceived', data)
      })

      this.socket.on('lessonModesChanged', data => {
        console.log('Debug socket:', {
          event: 'lessonModesChanged',
          host: this.host,
          data,
        });
        if(!this.host) {
          // console.log(data.modes)
          this.$store.commit('setHostLessonModesEnabled', JSON.parse(JSON.stringify(data.modes)))
          this.$store.commit('setLessonModesEnabled', this.isMobile ? data.modes.slice(0,1) : data.modes)
          this.$store.commit('setLessonModeFull', data.fullMode)
          if(data.blockId) {
            EventBus.$emit('scrollToBlockId', data.blockId)
          }
        }
      })

      this.socket.on('show-responses-for-block', blockIds => { // enggo methodics - show responses to students for the block
        console.log('Debug socket:', {
          event: 'show-responses-for-block',
          host: this.host,
          blockIds,
        });
        this.$store.commit('setBlocksForResponses', blockIds)
      })

      this.socket.on('showcards', val => {
        console.log('Debug socket:', {
          event: 'showcards',
          host: this.host,
          val,
        });
        this.$store.commit('setShowCards', val)
      })

      this.socket.on('student-by-subrooms', data => { // student between subrooms was changed
        console.log('Debug socket:', {
          event: 'student-by-subrooms',
          host: this.host,
          data,
        });
        this.$store.commit('setStudentsBySubrooms', data)
      })

      this.socket.on('roomsSecondsLeft', data => {
        console.log('Debug socket:', {
          event: 'roomsSecondsLeft',
          host: this.host,
          data,
        });
        this.$store.commit('setRoomsSecondsLeft', data)
      })

      // this.socket.on('showPresentation', (val, mode) => { // between blackboard and slides
      //   this.showPresentation = val
      // })

      this.socket.on('wbCanvasToJson', (json) => {
        console.log('Debug socket:', {
          event: 'wbCanvasToJson',
          host: this.host,
          json,
        });
        EventBus.$emit('wbCanvasToJson', json)
      })

      this.socket.on('blackboardCursorMove', (coords) => {
        console.log('Debug socket:', {
          event: 'blackboardCursorMove',
          host: this.host,
          coords,
        });
        EventBus.$emit('blackboardCursorMove', coords)
      })

      this.socket.on('materialChanged', () => {
        this.getSlides(true)
      })

      this.socket.on('connect', () => {
        console.log('Debug socket:', {
          event: 'connect',
          host: this.host,
          userId: this.userId,
          socket: this.socket,
          connection: this.connection
        });
        console.log('socket connected')
      })



      // document.addEventListener("readystatechange", () => {
      //   console.log('resume')
      // });
      // this.socketTimer = setInterval(() => {
      //   if(!this.socket.connected) this.socket.connect()
      // }, 5000);
    },
    setUpPeerActions() {

      console.log('setting up peer actions')

      if (!this.myVideoPeer) {
        this.myVideoPeer = new Peer(this.peerConfig)
      }

      this.myVideoPeer.on('open', id => { // start, called initially
        console.log("my video peer opened with id " + id)
        this.userId = id
        this.$store.commit('setMyPeerId', id)
        if(!this.socket && this.viewMode !== 'homeworkReview') location.reload()
        if (this.host) {
          this.$store.commit('setHostPeerId', id)
          this.socket.emit('hostid', this.room, id) // broadcasts "hostidreceived"
        }
        this.socket.emit('join-room', this.room, id, this.$store.state.user ? this.$store.state.user.id : 'demo', this.host) // broadcasts "user-connected"
      })

      this.myVideoPeer.on('error', error => {
        console.log('video peer error listener')
        console.log(error)
        this.myVideoPeer.destroy();
        this.myVideoPeer = null;
      })

      this.myVideoPeer.on('disconnected', () => {
        console.log('video peer disconnected')
        if(this.myVideoPeer) this.myVideoPeer.destroy();
        this.myVideoPeer = null;
        if(this.myVideoPeerTimer) clearInterval(this.myVideoPeerTimer);
        this.myVideoPeerTimer = setInterval(() => {
          if(!this.myVideoPeer) {
            this.launchMedia()
          }
        },1000)
      })

      this.myVideoPeer.on('connection', con => {
        console.log('connected peer')
        this.settingPeerConnectionChannel(con.peer, con)
      })

      this.myVideoPeer.on('call', call => { // when someone calls us
        call.answer(this.localStream ? this.localStream : createMediaStreamFake())
        call.on('stream', userVideoStream => {
          this.addVideoStream(userVideoStream, call.peer, call.metadata)
        })
      })

      if(this.myVideoPeerTimer) {
        clearInterval(this.myVideoPeerTimer);
      }
    },
    handleConnectivityChange(status) {
      // console.log(status)
      // console.log(this.socket)
      if(this.socket) {
        this.socket.emit('join-room', this.room, this.userId, this.$store.state.user ? this.$store.state.user.id : 'demo', this.host) // broadcasts "user-connected"
      }
      if(status && !this.myVideoPeer) {
        this.launchMedia()
      }
    },
    processConnectionData(data) {
      if (data && data.type) {
        if (data.type == "drawing") {
          EventBus.$emit('drawfromhost', data.lines, data.slide)
        }
        if (data.type == "cursor") {
          EventBus.$emit('cursor', data.x, data.y)
        }
        if (data.type == "presentationCursor") {
          EventBus.$emit('presentationCursor', data.x, data.y, data.scroll, data.onlyScroll)
        }
        if (data.type == "userId") {
          this.$set(this.usersdata[data.peerId], 'dbUserId', data.dbId)
          this.$set(this.usersdata[data.peerId], 'host', data.host)
        }
        if (data.type == "follow") {
          this.$store.commit('setHostPeerId', data.hostId)
          this.$store.commit('setFollowed', data.data)
          this.$nextTick(() => {
            if (this.followed) {
              this.$refs.canvascomponent.changeSlide()
            }
          })
        }
        if (data.type == "slidechanged") {
          this.$refs.canvascomponent.slide = data.data
        }
        if (data.type == "taskchanged") {
          EventBus.$emit('slideTaskChangedReceive', data.component_id, data.data)
        }
        if (data.type == "scrolled") {
          EventBus.$emit('scrollReceived', data.data)
        }
        if(data.type == "mute") {
          this.mute()
        }
        if(data.type == "videomute") {
          this.videomute()
        }
      }
    },
    checkForInitUserConfig(userId) {
      if (!{}.hasOwnProperty.call(this.usersdata, userId)) {
        this.$set(this.usersdata, userId, {
          audio: true,
          video: true,
          remotestream: [],
          peer: null,
          dbUserId: null,
          connection: null,
        })
      }
    },
    mute() {
      console.log('mute got')
      console.log(this.localStream)
      console.log(this.localStream.getAudioTracks())
      if (this.localStream && this.localStream.getAudioTracks().length > 0) {
        console.log('muting')
        this.muted = this.localStream.getAudioTracks()[0].enabled
        this.localStream.getAudioTracks()[0].enabled = !this.muted
        this.socket.emit('customevent', 'audio-switch', this.room, this.userId, !this.muted)
      }
    },
    videomute() {
      if (this.localStream && this.localStream.getVideoTracks().length > 0) {
        this.videomuted = this.localStream.getVideoTracks()[0].enabled
        this.localStream.getVideoTracks()[0].enabled = !this.videomuted
        this.socket.emit('customevent', 'video-switch', this.room, this.userId, !this.videomuted)
      }
    },
    emitModeChange(blockId = null) {
      if(this.host && this.socket) {
        this.socket.emit('customevent', 'slidemodechanged', this.room, this.slidemode)
        this.socket.emit('customevent', 'slidechanged', this.room, this.slide, this.finishedLesson)
        this.socket.emit('customevent', 'lessonModesChanged', this.room, {
          modes: this.lessonModesEnabled,
          fullMode: this.lessonModeFull,
          blockId: blockId
        })
      }
    },
    showBlockResponses() {
      if(!this.socket) return
      this.socket.emit('customevent', 'show-responses-for-block', this.room, this.$store.state.call.blocksShowingResponses)
    },
    async materialSelected(m) {
      if(!this.host) return
      await LessonsService.update(this.lesson.id, {
        material_id: m.id,
        group_id: this.lesson.group_id,
        repeating: this.lesson.repeating
      })
      if(this.lessonModesEnabled.includes('presentation')) this.$store.commit('toggleLessonMode', 'presentation')
      this.$refs.canvascomponent.slidemodechange('lesson')
      if(this.socket) {
        this.$store.commit('setSpecificSlide', 0)
        this.$store.commit('setFinishedLesson', false)
        this.$refs.canvascomponent.changeSlide()
        this.socket.emit('customevent', 'materialChanged', this.room)
      }
      await this.getSlides(true)
    },
    async getSlides(repetitive = false) {
      try {
        let res = await LessonsService.getSlidesForLesson(this.room, this.code, this.homeworkStudentId)
        this.$store.commit('setSlides', res.data.data.slides)
        const words = res.data.data.material?.words || []
        this.$store.commit('setLessonWords', words)
        this.words = words
        this.lesson = res.data.data.lesson
        this.$store.commit('setMaterial', this.lesson.material)
        this.$store.commit('setLessonCards', res.data.data.cards)
        this.$store.commit('setLessonGroup', res.data.data.lesson.group)
        this.cards = res.data.data.cards
        this.school = res.data.data.school
        this.$store.commit('setLessonSchool', this.school)
        this.linkchat = this.school.linkchat
        if(this.lesson.streaming_type === 'edumeet') this.linkchat = true
        if(this.lesson.streaming_type === 'webrtc') this.linkchat = false
        if(this.lesson.streaming_type === 'jaas') {
          this.linkchat = true
          this.jaasEnabled = true
        }
        this.mirotalk = this.school.mirotalk
        this.$store.commit('setMethodics', this.lesson.methodics)
        this.$store.commit('setLessonType', this.lesson.lesson_type)
        if(!repetitive) this.students = res.data.data.students
        if(this.lesson.lesson_type == 'demo') {
          this.students = {
            'demo': { name: 'Student', id: 'demo'}
          }
        }
        // if (this.lesson.lesson_type == 'lesson' && Object.keys(this.students).length < 2) {
        //   this.terminal_error = this.$t('lesson_not_found')
        //   this.loading = false
        //   return false
        // }

        if (this.$store.state.user && this.$store.state.user.id === this.lesson.instructor_id) {
          console.log("I AM A HOST")
          this.$store.commit('setHost', true)
          this.$store.commit('setHostId', this.$store.state.user.id)
          if(this.homeworkStudentId && res.data.data.homework) {
            await this.getUserTasks({
              user_id: this.homeworkStudentId,
              id: this.lesson.id,
              type: 'lesson'
            })
          }
        }

        this.$nextTick(() => {
          this.canvasLaunched = true
          if(this.lesson.lesson_type == 'lesson') {
            if (!this.lesson.used) {
              setTimeout(() => {
                LessonsService.used(this.lesson.id)
              }, this.setUsedTime * 1000)
            }
          }
        })
        return true
      } catch (e) {
        console.log(e)
        console.log(e.response.status)
        if(e.response.status == 404 && !this.$store.state.user) {
          this.$store.commit('setNextRedirectUrl', this.$route.path)
          this.$router.push('/login')
        }
        this.loading = false
        return false
      }
    },
    homeworkDone() {
      if (!this.lesson) return
      let params = {
        lesson_id: this.lesson.id,
      }
      if(this.host && this.homeworkStudentId) {
        params.student_id = this.homeworkStudentId
        StudentPageService.homeworkReviewDone(params).then(() => {
          this.stop()
        })
      } else {
        params.homework = TaskStorage.getLessonHomework(this.lesson.id)
        StudentPageService.homeworkDone(params).then(() => {
          this.$swal({
            title: this.$t('done'),
            text: this.$t('your_homework_is_sent_for_review'),
            confirmButtonText: this.$t('good'),
            customClass: {
              image:'swal-image-confirm',
            },
            imageUrl: '/images/tick2.png',
          }).then(this.stop)
        })
      }
    },
    sendStatusDataToSockets() {
      if(this.socket) {
        this.socket.emit('customevent', 'state-data', this.room, {
          mode: this.mode,
          blackboardJson: this.blackboardJson,
          secondsLeft: this.roomsSecondsLeft,
          // linkchatUrl: this.linkchatUrl,
          hostPeerId: this.hostPeerId,
          studentsBySubrooms: this.studentsBySubrooms,
        })
      }
    },
    sendGuestStatusDataToSockets() {
      if(this.socket) {
        this.socket.emit('customevent', 'state-guest-data', this.room, {
          studentsBySubrooms: this.studentsBySubrooms,
          secondsLeft: this.roomsSecondsLeft,
        })
      }
    },
    checkIfNotDisconnected() {
      if(this.host || !this.$store.state.user || !this.hostPeerId) return
      LessonsService.checkIfIAmInClass(this.lesson.id, this.$store.state.user.id).then(res => {
        if(!res.data) {
          console.log('I am not in class')
          if(this.socket) {
            this.socket.disconnect()
            this.socket.close()
          }
          this.socket = null
          this.myVideoPeer.disconnect()
          this.setUpSockets(true)
          this.$nextTick(() => {
            this.launchMedia()
          })
        }
      })
    },
    async updateStudentsList() {
      const res = await LessonsService.getStudentsForLesson(this.room, this.code)
      this.students = res.data.data.students
    },
    testMethod() {
      this.socket.disconnect()
      this.socket.close()
      this.socket = null
      this.myVideoPeer.disconnect()
      this.setUpSockets(true)
      this.$nextTick(() => {
        this.launchMedia()
      })
      // console.log(this.usersdata)
      // this.socketTestMsg = this.idsOfUsers.join(', ')
    }
  },
  beforeRouteLeave(to, from, next) {
    if(!this.homework && this.$store.state.user) {
      this.$confirm(this.$t('are_you_sure_you_want_to_leave_page')).then(() => {
        next()
      })
    } else {
      next()
    }
  },
  async mounted() {
    this.$store.commit('setLessonModesEnabled', [])
    this.$store.commit('setFollowed', false)
    this.$store.commit('setRoom', this.room)
    this.$store.commit('setIsHomework', this.homework)
    if (isIOS && (isChrome || isChromium)) {
      this.terminal_error = this.$t('your_device_does_not_support_video')
    }

    if (!await this.getSlides()) {
      this.terminal_error = this.$t('lesson_not_found')
      return
    }

    if(!this.homework) this.$store.commit('setViewMode', 'lesson')
    if(this.homework) {
      this.$store.commit('toggleLessonMode', 'slide')
      this.$store.commit('toggleLessonMode', 'presentation')
      if(this.homeworkStudentId && this.host) {
        this.$store.commit('setViewMode', 'homeworkReview')
      } else {
        let studentHomeworkData = this.lesson.students.find(x => x.id == this.$store.state.user.id)
        if(studentHomeworkData && studentHomeworkData.pivot && studentHomeworkData.pivot.homework_reviewed == 1) {
          this.$store.commit('setViewMode', 'homeworkReview')
        } else {
          this.$store.commit('setViewMode', 'homework')
        }
      }
    }

    if(!(this.homeworkStudentId && this.homework) && this.lesson.lesson_type == 'lesson') {
      await this.getUserTasks({
        id: this.lesson.id, type: 'lesson'
      })
    }
    this.$store.commit('setLessonId', this.lesson.id)

    if (!this.homework) {
      this.setUpSockets()
      this.$nextTick(() => {
        this.launchMedia()
        if(this.lesson.lesson_type == 'lesson') {
          setInterval(this.checkIfNotDisconnected, 6000)
          setTimeout(() => { // checking if only one user from account is present
            if (this.idsOfUsers.includes(this.$store.state.user.id)) {
              this.terminal_error = this.$t('your_account_is_already_in_the_class')
              setTimeout(() => {
                location.href = '/'
              },4000)
            }
          },10000)
        }
      })
    } else {
      this.loading = false
    }

    // navigator.mediaDevices.addEventListener('devicechange', event => { // plug / unplug earphones
    //   console.log(event)
    // });

    if (!this.homework) {
      EventBus.$on('slideTaskChangedSend', (component_id, data, blockType) => {
        if ((this.followed || blockType == 'IM02') && this.hostPeerId && this.usersdata[this.hostPeerId]) { // IM02 (upload photos through qr) we send always even if host is not watching
          this.usersdata[this.hostPeerId].connection.send({
            type: 'taskchanged',
            component_id: component_id,
            data: data
          })
        }
        if (this.host && this.selectedUserPeerId && this.usersdata[this.selectedUserPeerId]) {
          this.usersdata[this.selectedUserPeerId].connection.send({
            type: 'taskchanged',
            component_id: component_id,
            data: data
          })
        }
      })
      EventBus.$on('scrollToTask', (index) => {
        if (this.followed && this.hostPeerId && this.usersdata[this.hostPeerId]) {
          this.usersdata[this.hostPeerId].connection.send({
            type: 'scrolled',
            data: index
          })
        }
      })
      EventBus.$on('youtubeChangeSent', (component_id, data) => {
        if(this.socket) {
          this.socket.emit('customevent', 'youtubechanged', this.room, {
            component_id: component_id,
            data: data
          })
        }
      })
      EventBus.$on('mute', this.mute)
      EventBus.$on('videomute', this.videomute)
      EventBus.$on('muteuser', (peerId) => {
        this.usersdata[peerId] && this.usersdata[peerId].connection.send({
          type: 'mute',
        })
      })
      EventBus.$on('videomuteuser', (peerId) => {
        this.usersdata[peerId] && this.usersdata[peerId].connection.send({
          type: 'videomute',
        })
      })
      EventBus.$on('showBlockResponses', this.showBlockResponses)
      EventBus.$on('newChatMessage', () => {
        this.$store.commit('setNewMessage', true)
      })
    }
  },
  watch: {
    videomuted() {
      this.dataTransfer()
    },
    muted() {
      this.dataTransfer()
    },
    lessonModesEnabled: {
      deep: true,
      handler() {
        this.emitModeChange()
      }
    },
    lessonModeFull() {
      this.emitModeChange()
    },
    selectedBlockId(val) {
      if(val) {
        this.emitModeChange(val)
      }
    },
    studentsBySubrooms(val, oldval) {
      if(!Object.values(val).length && !Object.values(oldval).length) return
      if(this.host && this.socket) {
        this.socket.emit('customevent', 'student-by-subrooms', this.room, val)
      }
    },
    roomsSecondsLeft(val) {
      if(this.host && this.socket) this.socket.emit('customevent', 'roomsSecondsLeft', this.room, val)
    },
  },
  metaInfo() {
    return {
      title: (this.lesson && this.lesson.material) ? this.lesson.material.title : this.$t('lesson'),
    }
  },
}
</script>

<style scoped lang="scss">
.video-row {
  position: fixed;
  width: calc(100vw - 72px);
  top: 0;
  border-left: 1px solid $border-color;
  &.half {
    width: calc(50vw - 36px);
    right: 0;
    left: auto;
  }
  &.fourth {
    width: 145px;
    right: 0;
    left: auto;
  }
}
@media(max-width: 768px) {
  .video-row {
    width: 100%;
    &.half {
      width: 100vw;
    }
  }
}
</style>
